import Experience from "../Experience";

export default class Scrollbar {
  constructor(enableScrollbar) {
    this.experience = new Experience();
    this.gsap = this.experience.gsap;
    this.lenis = this.experience.lenis;

    this.activeTrackitem = null;
    this.isOverScrollbar = false;
    this.adjustTrackDivsBasedOnScroll = true;

    if (enableScrollbar) {
      this.Setup();
      this.AnimateOnScroll();
      this.ShowLinks();
      this.AnimateScrollbar();
      this.AddLinks();
    }
  }

  Setup() {
    this.wrapper = document.querySelector("[data-scrollbar]");
    this.trackitems = this.gsap.utils.toArray(".trackitem");
    this.numtrackdivs = Math.floor(window.innerHeight / 60) * 0.2;
    this.chaptersIDs = [
      "uebersicht",
      "digitalstrategie",
      "zielbilder-one",
      "zielbilder-two",
      "initiativen",
      "leitlinien",
    ];
    this.chapters = this.chaptersIDs.map((chapterID) =>
      document.querySelector(`#${chapterID}`)
    );

    this.viewportHeight = window.innerHeight;
    this.multipliers = this.chapters.map(
      (chapter) => chapter.clientHeight / this.viewportHeight
    );

    this.trackitems.forEach((trackitem, index) => {
      let dynamicNumTrackdivs = Math.floor(
        this.numtrackdivs * this.multipliers[index]
      );

      for (let i = 0; i < dynamicNumTrackdivs; i++) {
        let trackdiv = document.createElement("div");
        trackdiv.classList.add("trackdiv");
        this.wrapper.insertBefore(trackdiv, trackitem.nextSibling);
      }
    });

    // for (let i = 0; i < this.numtrackdivs / 12; i++) {
    //   let trackdiv = document.createElement("div");
    //   trackdiv.classList.add("trackdiv");
    //   this.wrapper.insertBefore(trackdiv, this.trackitems[0]);
    // }
  }

  AnimateOnScroll = () => {
    this.trackdivs = this.gsap.utils.toArray(".trackdiv");

    document.addEventListener("scroll", () => {
      if (!this.adjustTrackDivsBasedOnScroll) return;

      let scrollPosition = window.scrollY;
      let totalHeight =
        document.documentElement.scrollHeight - window.innerHeight;

      // Normalize the scroll position to a range from 0 to 1
      let normalizedPosition = scrollPosition / totalHeight;

      this.trackdivs.forEach((div, index, array) => {
        let divIndexNormalized = index / (array.length - 1);

        let distance = Math.abs(divIndexNormalized - normalizedPosition);
        let width = 4 - Math.min(distance * 50, 3); // in em units

        this.gsap.to(div, { width: `${width}rem`, duration: 0.3 });
      });
    });
  };

  ShowLinks = () => {
    this.trackitems.forEach((trackitem, index) => {
      let correspondingChapter = this.chapters[index];

      this.gsap.to(trackitem, {
        scrollTrigger: {
          trigger: correspondingChapter,
          start: "top center",
          end: "bottom 80%",
          scrub: true,

          onEnter: () => {
            this.activeTrackitem = trackitem;
            this.gsap.to(trackitem, {
              width: "5em",
              duration: 0.3,
            });
            trackitem.classList.add("active");
          },
          onLeave: () => {
            if (!this.isOverScrollbar) {
              this.activeTrackitem = null;
              if (!trackitem.matches(":hover")) {
                this.gsap.to(trackitem, {
                  width: "1em",
                  duration: 0.3,
                });
                trackitem.classList.remove("active");
              }
            }
          },
          onEnterBack: () => {
            this.activeTrackitem = trackitem;
            this.gsap.to(trackitem, {
              width: "5em",
              duration: 0.3,
            });
            trackitem.classList.add("active");
          },
          onLeaveBack: () => {
            if (!this.isOverScrollbar) {
              this.activeTrackitem = null;
              if (!trackitem.matches(":hover")) {
                this.gsap.to(trackitem, {
                  width: "1em",
                  duration: 0.3,
                });
                trackitem.classList.remove("active");
              }
            }
          },
          onRefresh: (self) => {
            if (self.progress !== 0 && self.progress !== 1) {
              trackitem.classList.add("active");
            }
          },
        },
      });
    });
  };

  AnimateScrollbar = () => {
    this.wrapper.addEventListener("mouseenter", () => {
      this.gsap.to(this.trackitems, {
        width: "5em",
        duration: 0.15,
      });
    });
    this.wrapper.addEventListener("mouseleave", () => {
      this.trackitems.forEach((trackitem) => {
        if (trackitem !== this.activeTrackitem) {
          this.gsap.to(trackitem, {
            width: "1em",
            duration: 0.15,
          });
        }
      });
    });
  };

  AddLinks = () => {
    const anchor = [...document.querySelectorAll("[data-scrolllink]")];
    const firstAnchor = anchor.shift();

    firstAnchor.addEventListener("click", () => {
      this.lenis.instance.scrollTo(0);
    });

    if (anchor.length > 0) {
      anchor.forEach((item) => {
        const id = parseFloat(item.dataset.scrolllink);

        const target = document.querySelector(`[data-scrolltarget="${id}"]`);

        if (target) {
          item.addEventListener("click", () => {
            this.lenis.instance.scrollTo(target);
          });
        }
      });
    }
  };
}
