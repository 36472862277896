import Experience from "../Experience";
import * as THREE from "three";

export default class MouseEvents {
  constructor() {
    this.experience = new Experience();
    this.camera = this.experience.camera;
    this.cursor = new THREE.Vector2();
    this.raycaster = new THREE.Raycaster();
    this.gsap = this.experience.gsap;
    this.sizes = this.experience.sizes;
    this.intersections = null;
    this.currentIntersect = null;
    this.element = document.querySelector("#dynamic");
    // this.getMouseCoordinates();
  }

  objectIntersects = (objects) => {
    this.raycaster.setFromCamera(this.cursor, this.camera.instance);
    this.intersections = this.raycaster.intersectObjects(objects);
    return this.intersections;
  };

  getMouseCoordinates(animation, objects) {
    window.addEventListener("mousemove", (event) => {
      this.cursor.x = (event.clientX / this.sizes.width) * 2 - 1;
      this.cursor.y = (event.clientY / this.sizes.height) * -2 + 1;
      if (animation) {
        animation();
      }
      if (objects) {
        // this.checks = this.objectIntersects(objects);
        // if (this.checks.length) {
        //   if (!this.currentIntersect) {
        //     console.log("mouse enter");
        //     // this.gsap.to(this.checks[0].object.scale, {
        //     //   x: 2,
        //     //   y: 2,
        //     //   z: 2,
        //     //   duration: 0.5,
        //     //   ease: "power3.out",
        //     // });
        //     // this.element.innerHTML = this.checks[0].object.leitlinien;
        //     // this.checks[0].object.scale.set(2, 2, 2);
        //   }
        //   this.currentIntersect = this.checks[0];
        // } else {
        //   if (this.currentIntersect) {
        //     console.log("mouse leave");
        //     // this.element.innerHTML = "";
        //     // this.gsap.to(this.currentIntersect.object.scale, {
        //     //   x: 1,
        //     //   y: 1,
        //     //   z: 1,
        //     //   duration: 0.5,
        //     //   ease: "power3.out",
        //     // });
        //     // this.currentIntersect.object.scale.set(1, 1, 1);
        //   }
        //   this.currentIntersect = null;
        // }
      }
    });
  }

  update() {}
}
