import BaseAnimation from "./BaseAnimation.js";

export default class Board extends BaseAnimation {
  constructor() {
    super();
  }

  enterBoard = () => {
    this.tl = this.gsap.timeline({});
    this.tl
      .to(
        this.targets.group.position,
        {
          x: -6.5,
          y: -2.5,
        },
        0
      )
      .to(
        this.targets.group.scale,
        {
          x: 1,
          y: 1,
          z: 1,
        },
        0
      );
    return this.tl;
  };

  ballAnimation = () => {
    this.gsap.to(this.targets.group.position, {
      y: -4.5,
      scrollTrigger: {
        trigger: ".board",
        start: "top top",
        end: "bottom 75%",
        scrub: true,
      },
    });
  };

  init() {
    this.enterBoard();
  }
}
