import i18next from "i18next";
import Backend from "i18next-http-backend";

let instance = null;

export default class TranslationController {
  constructor(_options = {}) {
    //turns this class into singleton: it's instantiated once, and for next times it returns the first instance (copy)
    if (instance) {
      return instance;
    }
    this.currentLanguage;

    instance = this;

    this.init();
  }

  async setup() {
    await i18next.use(Backend).init({
      lng: "de",
      supportedLngs: ["en", "de"],
      fallbackLng: "de",
      ns: ["common", "home", "board", "digitalstrategie"],

      backend: {
        loadPath: "/locales/{{lng}}/{{ns}}.json",
      },
    });
  }

  translate = () => {
    const elements = document.querySelectorAll("[data-i18n-key]");
    elements.forEach((element) => {
      const key = element.getAttribute("data-i18n-key");
      element.innerHTML = i18next.t(key);
    });
  };

  bindLocaleSwitcher = (initialValue) => {
    const switchers = document.querySelectorAll("[data-i18n-switcher]");
    switchers.forEach((switcher) => {
      switcher.value = initialValue;
      this.currentLanguage = initialValue;
      switcher.onchange = (e) => {
        i18next
          .changeLanguage(e.target.value)
          .then(() => {
            this.currentLanguage = e.target.value;
          })
          .then(this.translate)
          .then(this.hideUntranslated);
      };
    });
  };

  hideUntranslated() {
    const elements = document.querySelectorAll("[data-hide-untranslated]");
    i18next.on("languageChanged", (lng) => {
      if (lng === "en")
        elements.forEach((element) => {
          element.style.visibility = "hidden";
        });
      else {
        elements.forEach((element) => {
          element.style.visibility = "visible";
        });
      }
    });
  }

  async init() {
    await this.setup();
    this.bindLocaleSwitcher(i18next.resolvedLanguage);
    this.translate();
    this.hideUntranslated();
    this.currentLanguage = i18next.resolvedLanguage;
  }
}
