import Experience from "../Experience";
import * as THREE from "three";
export default class Particles {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.group = this.experience.world.group;
    this.particles;
    this.particlesCount = 200;
    this.particleSize = 15;
    this.particleTexture_1;

    this.initParticles();
  }
  setTextures = () => {
    const textureLoader = new THREE.TextureLoader();
    this.particleTexture_1 = textureLoader.load(
      "/textures/particles/twirl_02.png"
    );
  };
  setMaterial = () => {
    this.particlesMaterial = new THREE.PointsMaterial({
      size: this.particleSize,
      sizeAttenuation: true,
      depthWrite: false,
      vertexColors: true,
      blending: THREE.AdditiveBlending,
      transparent: true,
      alphaMap: this.particleTexture_1,
      fog: false,
      opacity: 0.5,
      //   color: 0x000000,
    });
  };

  setParticles = () => {
    this.particlesGeometry = new THREE.BufferGeometry();
    this.insideColor = new THREE.Color(0xffdbcc);

    this.posArray = new Float32Array(this.particlesCount * 3);
    this.colors = new Float32Array(this.particlesCount * 3);
    for (let i = 0; i < this.particlesCount * 3; i++) {
      const i3 = i * 3;
      this.posArray[i] = (Math.random() - 0.5) * 50;
      this.colors[i3] = this.insideColor.r;
      this.colors[i3 + 1] = this.insideColor.g;
      this.colors[i3 + 2] = this.insideColor.b;
    }
    this.particlesGeometry.setAttribute(
      "position",
      new THREE.BufferAttribute(this.posArray, 3)
    );
    this.particlesGeometry.setAttribute(
      "color",
      new THREE.BufferAttribute(this.colors, 3)
    );

    this.particles = new THREE.Points(
      this.particlesGeometry,
      this.particlesMaterial
    );

    this.scene.add(this.particles);

    this.particles.name = "particleSystem";
    this.particleSystem = this.scene.getObjectByName("particleSystem");
  };

  initParticles() {
    this.setTextures();
    this.setMaterial();
    this.setParticles();
  }
  update() {
    this.particleSystem.rotation.y += 0.0003;
  }
}
