import * as THREE from "three";
import vertexShader from "./shaders/vertex.glsl";
import fragmentShader from "./shaders/fragment.glsl";

import Experience from "../Experience";

export default class World {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;

    this.group;
    this.subgroup;
    this.leitlinien = [];
    this.occluders = [];
    this.icosahedron;

    this.color = 0xffcbb5;

    this.setTextures();
    this.initModels();

    this.scene.fog = new THREE.Fog(0x02001f, 10, 12);
  }

  setTextures() {
    const textureLoader = new THREE.TextureLoader();
    this.matcapTexture = textureLoader.load("/textures/matcaps/blue_dark.png");
    this.leitlinienTexture = textureLoader.load(
      "/textures/icons/leitlinien.png"
    );
  }

  twist = (geometry, factor) => {
    const q = new THREE.Quaternion();
    const up = new THREE.Vector3(0, 1, 0);
    const p = geometry.attributes.position.array;

    for (let i = 0; i < p.length; i += 3) {
      q.setFromAxisAngle(up, p[i + 1] * factor);

      let vec = new THREE.Vector3(p[i], p[i + 1], p[i + 2]);
      vec.applyQuaternion(q);

      p[i] = vec.x;
      p[i + 2] = vec.z;
    }

    geometry.computeVertexNormals();
    geometry.attributes.position.needsUpdate = true;
  };

  ToQuads = (g) => {
    let p = g.parameters;
    let segmentsX =
      (g.type == "TorusBufferGeometry"
        ? p.tubularSegments
        : p.radialSegments) ||
      p.widthSegments ||
      p.thetaSegments ||
      p.points.length - 1 ||
      1;
    let segmentsY =
      (g.type == "TorusBufferGeometry"
        ? p.radialSegments
        : p.tubularSegments) ||
      p.heightSegments ||
      p.phiSegments ||
      p.segments ||
      1;
    let indices = [];
    for (let i = 0; i < segmentsY + 1; i++) {
      let index11 = 0;
      let index12 = 0;
      for (let j = 0; j < segmentsX; j++) {
        index11 = (segmentsX + 1) * i + j;
        index12 = index11 + 1;
        let index21 = index11;
        let index22 = index11 + (segmentsX + 1);
        indices.push(index11, index12);
        if (index22 < (segmentsX + 1) * (segmentsY + 1) - 1) {
          indices.push(index21, index22);
        }
      }
      if (index12 + segmentsX + 1 <= (segmentsX + 1) * (segmentsY + 1) - 1) {
        indices.push(index12, index12 + segmentsX + 1);
      }
    }
    g.setIndex(indices);
  };

  setGeom = (g, color, x, y, z) => {
    this.ToQuads(g);
    let m = new THREE.LineBasicMaterial({
      color: color,
      transparent: true,
      // matcap: this.matcapTexture,
    });
    let o = new THREE.LineSegments(g, m);
    o.position.set(x, y, z);
    return o;
  };

  createLeitlinien = (group) => {
    for (let i = 0; i < 7; i++) {
      let occluder = new THREE.Mesh(
        new THREE.PlaneGeometry(1.94 * 0.28, 1.68 * 0.28),
        new THREE.MeshBasicMaterial({
          color: this.color,
          transparent: true,
          opacity: 0,
        })
      );
      occluder.renderOrder = -1;

      occluder.position.set(
        7 * Math.sin((Math.PI / 3.49) * i),
        7 * Math.cos((Math.PI / 3.49) * i),
        0.2
      );

      let line = new THREE.Mesh(
        new THREE.PlaneGeometry(1.94 * 0.28, 1.68 * 0.28),
        new THREE.MeshBasicMaterial({
          color: this.color,
          map: this.leitlinienTexture,
          transparent: true,
          opacity: 0,
        })
      );

      line.position.set(
        7 * Math.sin((Math.PI / 3.49) * i),
        7 * Math.cos((Math.PI / 3.49) * i),
        0.21
      );

      this.leitlinien.push(line);
      group.add(line);

      this.leitlinien.push(occluder);
      this.occluders.push(occluder);
      this.group.add(occluder);
    }

    // this.spheres[0].leitlinien = "Offene Kultur";
    // this.spheres[1].leitlinien = "Einsatz innovativer Technologien";
    // this.spheres[2].leitlinien = "Digitale Kompetenzen";
    // this.spheres[3].leitlinien = "Gesellschaft und Nachhaltigkeit";
    // this.spheres[4].leitlinien = "Standardisierung und Vielfalt";
    // this.spheres[5].leitlinien = "Umgang mit schützenswerten Daten";
    // this.spheres[6].leitlinien =
    //   "Stabilität, Schutz, Verfügbarkeit der Systeme";
  };

  drawLine = (group) => {
    const geometry = new THREE.TorusGeometry(7, 0.015, 6, 64);
    const material = new THREE.MeshBasicMaterial({
      color: this.color,
      transparent: true,
      opacity: 0,
    });
    const line = new THREE.Mesh(geometry, material);

    group.add(line);
  };

  createIcosahedron = (group) => {
    const geometry = new THREE.IcosahedronGeometry(4, 0);
    const material = new THREE.MeshBasicMaterial({
      transparent: true,
      color: this.color,
      // opacity: 0,

      wireframe: true,
    });

    this.icosahedron = new THREE.Mesh(geometry, material);
    this.icosahedron.position.set(-50, 0, 0);
    this.icosahedron.rotateZ(THREE.MathUtils.degToRad(270));
    this.icosahedron.rotateY(THREE.MathUtils.degToRad(30));
    group.add(this.icosahedron);
  };

  createHexagon = (group) => {
    const geometry = new THREE.CircleGeometry(4, 6);
    const material = new THREE.MeshBasicMaterial({
      color: this.color,
      transparent: true,
      wireframe: true,
      // opacity: 0,
    });
    this.hexagon = new THREE.Mesh(geometry, material);
    this.hexagon.position.set(-50, 0, 2);
    group.add(this.hexagon);
  };

  initModels() {
    this.group = new THREE.Group();
    this.subgroup = new THREE.Group();
    this.group.position.set(0, 0, -1);
    this.wireframe = this.setGeom(
      new THREE.SphereGeometry(5, 12, 8),
      this.color,
      0,
      0,
      0
    );

    this.twist(this.wireframe.geometry, 0.15);

    const material = new THREE.MeshMatcapMaterial({
      matcap: this.matcapTexture,
      transparent: true,
      opacity: 1,
    });

    this.ball = new THREE.Mesh(new THREE.IcosahedronGeometry(5, 8), material);

    this.createLeitlinien(this.subgroup);
    this.drawLine(this.subgroup);
    this.createIcosahedron(this.group);
    this.createHexagon(this.group);
    this.ball.scale.set(1.02, 1.02, 1.02);
    this.group.add(this.subgroup);
    this.group.add(this.ball);
    this.group.add(this.wireframe);
    this.scene.add(this.group);
  }

  update() {}
  resize() {}
}
