import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { SplitText } from "gsap/SplitText";
import { Flip } from "gsap/Flip";

export default class Setup {
  constructor(gsap) {
    this.gsap = gsap;
    this.scrollTrigger = ScrollTrigger;
    this.flip = Flip;
  }
  registerPlugins() {
    this.gsap.registerPlugin(ScrollTrigger);
    this.gsap.registerPlugin(ScrollToPlugin);
    this.gsap.registerPlugin(SplitText);
    this.gsap.registerPlugin(Flip);
  }
  initializePlugins() {
    ScrollTrigger.defaults({
      immediateRender: false,
      ease: "power1.inOut",
    });
  }
}
