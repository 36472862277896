import Experience from "./Experience";
import * as THREE from "three";

export default class Renderer {
  constructor(_options = {}) {
    this.experience = new Experience();
    this.config = this.experience.config;
    this.sizes = this.experience.sizes;
    this.scene = this.experience.scene;
    this.camera = this.experience.camera;

    this.setInstance();
    // this.addShadows();
    // this.addToneMapping();
  }

  setInstance() {
    // Start of the code
    THREE.ColorManagement.enabled = false;
    this.instance = new THREE.WebGLRenderer({
      antialias: true,
      // preserveDrawingBuffer: true,
    });
    this.instance.domElement.style.position = "absolute";
    this.instance.domElement.style.top = 0;
    this.instance.domElement.style.left = 0;
    this.instance.domElement.style.width = "100%";
    this.instance.domElement.style.height = "100%";

    this.instance.setClearColor(0xffffff, 0);
    // this.instance.setClearColor(0x211d20);

    this.instance.setSize(this.config.width, this.config.height);
    this.instance.setPixelRatio(this.config.pixelRatio);
    // After instantiating the renderer
    this.instance.outputColorSpace = THREE.LinearSRGBColorSpace;
  }

  addShadows() {
    this.instance.shadowMap.enabled = true;
    this.instance.shadowMap.type = THREE.PCFShadowMap;
  }

  addToneMapping() {
    this.instance.toneMapping = THREE.CineonToneMapping;
    this.instance.toneMappingExposure = 1.75;
  }

  resize() {
    this.instance.setSize(this.config.width, this.config.height);
    this.instance.setPixelRatio(this.config.pixelRatio);
  }
  update() {
    this.instance.render(this.scene, this.camera.instance);
  }
}
