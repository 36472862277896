import Lenis from "@studio-freight/lenis";
import Experience from "../Experience";
import Navbar from "./Navbar";

export default class SmoothScroll {
  constructor() {
    this.instance = this.init();
    this.experience = new Experience();

    this.navbar = new Navbar();
    this.navbar.on("menu-open", () => {
      this.instance.stop();
    });
    this.navbar.on("menu-close", () => {
      this.instance.start();
    });
  }

  init() {
    const lenis = new Lenis();

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);
    return lenis;
  }
}
