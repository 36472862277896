import BaseAnimation from "./BaseAnimation.js";

export default class Home extends BaseAnimation {
  constructor() {
    super();

    this.words;
    this.bgLines;
    this.cards;
    this.tagline;

    this.wireframeAnimation = this.toggleWireframe();
    switch (window.location.pathname) {
      case "/digitalstrategie/index.html":
        break;
      case "/board/index.html":
        break;
      default:
        console.log("loaded from home");
        this.setHome();
        this.initBall();
        this.onLoadHome();
    }
  }
  onLoadHome() {
    this.headingAnimation(this.tagline);
  }
  setHome = () => {
    this.tagline = document.querySelector(".tagline");
    this.bgLines = document.querySelector(".lines");
    this.words = document.querySelectorAll(".word");
    this.cards = document.querySelectorAll(".card");
    this.items = document.querySelectorAll(".overview__column");
    this.scrollIcon = document.querySelectorAll(".mouse_scroll");
    this.gsap.set(this.bgLines, {
      autoAlpha: 0,
    });
    this.setHeadingAnimation(this.tagline);
  };

  initBall = () => {
    this.gsap.set(this.targets.group.position, {
      x: 0,
    });
    this.gsap.set(this.targets.group.scale, {
      x: 0.5,
      y: 0.5,
      z: 0.5,
    });
  };

  onEnterHome = () => {
    this.tl = this.gsap.timeline();

    this.tl
      .to(this.targets.group.position, { x: 0, y: 0 }, 0)
      .to(this.targets.group.scale, { x: 0.5, y: 0.5, z: 0.5 }, 0);
    return this.tl;
  };

  heroAnimation = () => {
    this.tl = this.gsap.timeline({
      paused: true,
      scrollTrigger: {
        trigger: ".hero",

        start: "top 79px",
        end: "bottom top",

        scrub: true,
        onEnterBack: () => {
          this.wireframeAnimation.reverse(0);
        },
      },
    });

    this.tl
      .to(
        this.targets.group.position,
        {
          x: 6.25,
        },
        0
      )
      .to(
        this.targets.group.scale,
        {
          x: 1,
          y: 1,
          z: 1,
        },
        0
      )
      .to(
        this.scrollIcon,
        {
          opacity: 0,
        },
        0
      );

    return this.tl;
  };
  strategieAnimation = () => {
    this.tl = this.gsap.timeline({
      paused: true,
      scrollTrigger: {
        trigger: ".loop",

        start: "bottom center",
        end: "+=200px",

        scrub: true,
        onEnter: () => {
          this.wireframeAnimation.play();
        },
      },
    });
    this.tl
      .to(this.bgLines, {
        autoAlpha: 0.08,
      })
      .to(this.targets.particles.material, {
        opacity: 0,
      });
    return this.tl;
  };

  wordsAnimation = () => {
    let mm = this.gsap.matchMedia();
    mm.add("(min-width: 64em)", () => {
      this.tl = this.gsap.timeline({
        paused: true,
        scrollTrigger: {
          trigger: ".overview",

          start: "top top",
          end: "+=2000px",
          pin: true,

          scrub: true,
        },
      });
      this.words.forEach((word, index) => {
        this.tl.from(
          word,
          {
            autoAlpha: 0,
            y: 80,
          },

          index * 0.5
        );
      });
    });

    return this.tl;
  };
  spinAnimation = () => {
    let mm = this.gsap.matchMedia();
    this.tween;
    mm.add("(min-width: 64em)", () => {
      this.tween = this.gsap.to(this.targets.wireframe.rotation, {
        y: Math.PI * 0.5,
        paused: true,
        scrollTrigger: {
          trigger: ".overview",
          start: "top top",
          end: "+=2000px",
          scrub: true,
        },
      });
    });

    return this.tween;
  };

  contentAnimation = () => {
    this.tl = this.gsap.timeline({
      paused: true,
      scrollTrigger: {
        trigger: ".content",
        start: "top bottom",
        end: "+=500px",
        scrub: true,
        onLeave: () => {
          this.wireframeAnimation.reverse(0);
        },
        onEnterBack: () => {
          this.wireframeAnimation.play();
        },
      },
    });

    this.tl
      .to(
        this.bgLines,
        {
          autoAlpha: 0,
        },
        0
      )
      .to(
        this.targets.particles.material,
        {
          opacity: 0.5,
        },
        0
      );
    return this.tl;
  };

  DOMAnimation = () => {
    this.gsap.from(this.items, {
      autoAlpha: 0,
      y: 80,
      x: -80,
      stagger: 0.1,
      scrollTrigger: {
        trigger: ".overview",

        start: "top center",
        end: "500px center",
      },
    });
    this.gsap.from(this.cards, {
      autoAlpha: 0,
      y: 80,
      x: -80,
      stagger: 0.1,
      scrollTrigger: {
        trigger: ".content",

        start: "bottom center",
        end: "+=200px",
      },
    });
  };

  init() {
    this.onEnterHome();
    this.heroAnimation();
    this.strategieAnimation();
    this.wordsAnimation();
    this.spinAnimation();
    this.contentAnimation();

    // specific tweens for DOM elements
    this.gsap.from(this.items, {
      autoAlpha: 0,
      y: 80,
      x: -80,
      stagger: 0.1,
      scrollTrigger: {
        trigger: ".overview",
        scrub: 0.5,

        start: "top center",
        end: "500px center",
      },
    });
    this.gsap.from(this.cards, {
      autoAlpha: 0,
      y: 80,
      x: -80,
      stagger: 0.1,
      scrollTrigger: {
        trigger: ".content",
        scrub: 0.5,

        start: "top center",
        end: "+=200px",
      },
    });
  }
}
