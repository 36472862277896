import { PerspectiveCamera, OrthographicCamera, MathUtils } from "three";
import { OrbitControls } from "three/addons/controls/OrbitControls.js";
import Experience from "./Experience";

export default class Camera {
  constructor() {
    this.experience = new Experience();
    this.config = this.experience.config;
    this.sizes = this.experience.sizes;
    this.scene = this.experience.scene;
    this.canvas = this.experience.canvas;
    this.targetElement = this.experience.targetElement;
    this.frustumSize = 15;
    this.aspect = this.config.width / this.config.height;

    this.setInstance();
    // this.setOrbitControls();
  }

  setInstance() {
    this.instance = new OrthographicCamera(
      (this.frustumSize * this.aspect) / -2,
      (this.frustumSize * this.aspect) / 2,
      this.frustumSize / 2,
      this.frustumSize / -2,
      0.01,
      1000
    );
    this.instance.position.set(0, 0, 10);
    this.scene.add(this.instance);
  }

  setOrbitControls() {
    this.controls = new OrbitControls(this.instance, this.canvas);
    this.controls.enableDamping = true;
    console.log(this.controls);
  }

  resize() {
    this.aspect = this.config.width / this.config.height;
    this.instance.left = (this.frustumSize * this.aspect) / -2;
    this.instance.right = (this.frustumSize * this.aspect) / 2;
    this.instance.top = this.frustumSize / 2;
    this.instance.bottom = this.frustumSize / -2;

    this.instance.updateProjectionMatrix();
  }

  update() {
    if (this.controls) this.controls.update();
  }
}
