import EventEmitter from "./EventEmitter";

export default class Sizes extends EventEmitter {
  constructor() {
    super();

    const container = document.querySelector(".app");

    //Setup
    this.width = container.offsetWidth;
    this.height = container.offsetHeight;
    this.pixelRatio = Math.min(window.devicePixelRatio, 2);

    //Resize event
    window.addEventListener("resize", () => {
      this.width = container.offsetWidth;
      this.height = container.offsetHeight;
      this.pixelRatio = Math.min(window.devicePixelRatio, 2);

      this.trigger("resize");
    });
  }
}
