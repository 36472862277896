import Setup from "./Setup.js";
import { SplitText } from "gsap/SplitText";
import Experience from "../Experience.js";
import SetTargets from "./SetTargets.js";
export default class BaseAnimation extends SetTargets {
  constructor() {
    super();
    this.experience = new Experience();
    this.gsap = this.experience.gsap;
    this.setup = new Setup(this.gsap);
    this.setup.registerPlugins();
    this.setup.initializePlugins();
    this.scrollTrigger = this.setup.scrollTrigger;
  }

  //Helper functions
  setOpacity = (obj, opacity, duration) => {
    obj.traverse((child) => {
      if (child.material) {
        this.gsap.to(child.material, {
          opacity: opacity,
          duration: duration,
        });
      }
    });
  };

  toggleWireframe = () => {
    this.tl = this.gsap.timeline({
      paused: true,
    });

    this.tl.to(
      this.targets.ball.material,
      {
        opacity: 0,
        duration: 0.25,
        onStart: () => {
          this.setOpacity(this.targets.leitlinien, 1, 0.25);
        },
        onReverseComplete: () => {
          this.setOpacity(this.targets.leitlinien, 0, 0.25);
        },
      },
      0
    );

    return this.tl;
  };

  //specific heading animations runned once

  setHeadingAnimation = (tagline) => {
    tagline.style.visibility = "visible";
    const split = new SplitText(tagline, {
      type: "lines",
      linesClass: "line",
    });
    this.lines = split.lines;

    this.lines.forEach((line) => {
      line.style.removeProperty("display");
    });
  };
  headingAnimation = (tagline) => {
    this.gsap.set(tagline, { autoAlpha: 1 });

    this.gsap.from(this.lines, {
      autoAlpha: 0,
      y: 80,
      stagger: 0.2,
      duration: 1,
      ease: "power3.out",
    });
  };

  //global destroy method
  destroy() {
    //destroy all scrolltriggers
    this.scrollTrigger.getAll().forEach((trigger) => {
      trigger.kill();
    });
  }
}
