import EventEmitter from "../Utils/EventEmitter";
import Experience from "../Experience";
export default class Navbar extends EventEmitter {
  constructor() {
    super();
    this.experience = new Experience();
    this.gsap = this.experience.gsap;
    this.itemsToMove = null;
    this.menuButton = null;
    this.mobileMenu = null;
    this.links = null;
    this.setElements();
  }

  setElements = () => {
    this.itemsToMove = document.querySelectorAll("[data-move]");
    this.menuButton = document.querySelector(".mobile__button");
    this.mobileMenu = document.querySelector(".mobile__nav");
    this.links = this.gsap.utils.toArray(".mobile__nav a");
  };
  animateMobileMenu = () => {
    this.played = false;
    this.gsap.set(this.mobileMenu, { xPercent: 100 });

    this.tl = this.gsap.timeline({ paused: true });
    this.tl
      .to(
        this.itemsToMove,
        {
          xPercent: -100,
          duration: 1,
          ease: "expo.inOut",
          onComplete: () => {},
        },
        0
      )
      .to(
        this.mobileMenu,
        {
          xPercent: 0,
          duration: 1,
          ease: "expo.inOut",
          onComplete: () => {},
        },
        0
      );

    this.menuButton.addEventListener("click", (e) => {
      e.preventDefault();
      //toggle this.played animation
      this.played = !this.played;
      if (this.played) {
        this.showMobileMenu();
        this.tl.play();
      } else {
        this.hideMobileMenu();
        this.tl.reverse();
      }
    });
    this.closeOnClickedLink();
  };

  showMobileMenu = () => {
    this.menuButton.classList.add("open");
    this.trigger("menu-open");
  };
  hideMobileMenu = () => {
    this.menuButton.classList.remove("open");
    this.trigger("menu-close");
  };

  closeOnClickedLink = () => {
    this.links.forEach((link) => {
      link.addEventListener("click", () => {
        this.hideMobileMenu();
        this.tl.reverse();
      });
    });
  };
}
