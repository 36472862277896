import Highway from "@dogstudio/highway";
import Navbar from "../Experience/Interactions/Navbar";

import Experience from "../Experience/Experience";
import TranslationController from "../Translation/TranslationController";

export default class HomeRenderer extends Highway.Renderer {
  constructor(properties) {
    super(properties);
    this.experience = new Experience();
    this.interactions = this.experience.homeInteractions;
    this.animations = this.experience.homeAnimations;
    this.translations = new TranslationController();
    this.navbar = new Navbar();
  }

  // Hooks/methods
  onEnter() {
    this.interactions.openVideo();
    this.translations.bindLocaleSwitcher(this.translations.currentLanguage);
    this.translations.translate();
  }
  onLeave() {}
  onEnterCompleted() {
    this.animations.setHome();
    this.animations.init();
    this.navbar.setElements();
    this.navbar.animateMobileMenu();
  }
  onLeaveCompleted() {
    this.animations.destroy();
  }
}
