import Experience from "./Experience/Experience";

import highway from "@dogstudio/highway";
import Fade from "./Transitions/Fade";
import HomeRenderer from "./Transitions/HomeRenderer";
import StrategieRenderer from "./Transitions/StrategieRenderer";
import BoardRenderer from "./Transitions/BoardRenderer";
import TranslationController from "./Translation/TranslationController";

const experience = new Experience({
  targetElement: document.querySelector(".app"),
});

const h = new highway.Core({
  renderers: {
    home: HomeRenderer,
    strategie: StrategieRenderer,
    board: BoardRenderer,
  },
  transitions: {
    default: Fade,
  },
});

const translationController = new TranslationController({
  message: "i'm the first instance",
});
