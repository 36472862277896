import Highway from "@dogstudio/highway";
import Scrollbar from "../Experience/Interactions/Scrollbar";
import Navbar from "../Experience/Interactions/Navbar";

import Experience from "../Experience/Experience";
import TranslationController from "../Translation/TranslationController";

export default class StrategieRenderer extends Highway.Renderer {
  constructor(properties) {
    super(properties);
    this.experience = new Experience();
    this.homeAnimations = this.experience.homeAnimations;
    this.animations = this.experience.strategyAnimations;
    this.navbar = new Navbar();
    this.enableScrollbar = false;
    this.translations = new TranslationController();
  }
  // Hooks/methods
  onEnter() {
    this.enableScrollbar = true;
    this.scrollbar = new Scrollbar(this.enableScrollbar);
    this.translations.bindLocaleSwitcher(this.translations.currentLanguage);
    this.translations.translate();
  }

  onLeave() {
    this.enableScrollbar = false;
    this.homeAnimations.wireframeAnimation.reverse(0);

    this.animations.destroy();
  }
  onEnterCompleted() {
    this.animations.init();
    this.homeAnimations.wireframeAnimation.play();
    this.navbar.setElements();
    this.navbar.animateMobileMenu();
  }
  onLeaveCompleted() {}
}
