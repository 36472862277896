import * as THREE from "three";
import Stats from "stats.js";
import Sizes from "./Utils/Sizes";
import Time from "./Utils/Time";
import Camera from "./Camera";
import Renderer from "./Renderer";
import MouseEvents from "./Utils/MouseEvents";
import World from "./World/World";
import Particles from "./World/Particles";
import gsap from "gsap";
import SmoothScroll from "./Interactions/SmoothScroll";

import Home from "./Animations/Home";
import Board from "./Animations/Board";
import Strategy from "./Animations/Strategy";

import Homepage from "./Interactions/Hompage";
import Scrollbar from "./Interactions/Scrollbar";
import Navbar from "./Interactions/Navbar";

let instance = null;

export default class Experience {
  constructor(_options = {}) {
    window.onbeforeunload = () => {
      window.scrollTo(0, 0);
    };

    //turns this class into singleton: it's instantiated once, and for next times it returns the first instance (copy)
    if (instance) {
      return instance;
    }

    instance = this;

    //Options
    this.targetElement = _options.targetElement;

    if (!this.targetElement) {
      console.warn("No target element defined");
      return;
    }

    this.gsap = gsap;

    //Setup
    this.sizes = new Sizes();
    this.time = new Time();
    this.setConfig();

    this.scene = new THREE.Scene();
    this.camera = new Camera();
    this.setRenderer();
    this.world = new World();
    this.particles = new Particles();
    this.mouseEvents = new MouseEvents();
    this.lenis = new SmoothScroll();

    //Animations
    this.homeAnimations = new Home();
    this.boardAnimations = new Board();
    this.strategyAnimations = new Strategy();

    //Interactions
    this.homeInteractions = new Homepage();
    this.scrollbar = new Scrollbar();

    //Resize Event from Sizes class
    this.sizes.on("resize", () => {
      this.resize();
    });

    //Tick Event from Time class
    this.time.on("tick", () => {
      this.update();
    });
  }

  setConfig() {
    this.config = {
      // debug: false,
      // stats: false,
    };
    this.config.pixelRatio = Math.min(Math.max(window.devicePixelRatio, 1), 2);

    const boundings = this.targetElement.getBoundingClientRect();
    this.config.width = boundings.width;
    this.config.height = boundings.height || window.innerHeight;
  }

  setRenderer() {
    this.renderer = new Renderer({ rendererInstance: this.rendererInstance });
    this.targetElement.appendChild(this.renderer.instance.domElement);
  }

  resize() {
    const boundings = this.targetElement.getBoundingClientRect();
    this.config.width = boundings.width;
    this.config.height = boundings.height;

    this.config.pixelRatio = Math.min(Math.max(window.devicePixelRatio, 1), 2);

    this.camera.resize();
    this.renderer.resize();
    this.world.resize();
    this.homeInteractions.resize();
  }
  update() {
    this.camera.update();
    this.renderer.update();
    this.mouseEvents.update();
    this.world.update();
    this.particles.update();
  }
}
