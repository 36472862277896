import BaseAnimation from "./BaseAnimation.js";
import { MathUtils } from "three";

export default class Strategy extends BaseAnimation {
  constructor() {
    super();
  }
  enterStrategy = () => {
    this.bgLines = document.querySelector(".lines");
    this.items = document.querySelectorAll(".overview__column");
    this.tl = this.gsap.timeline({ duration: 1.5, ease: "power4.out" });
    this.tl
      .to(
        this.targets.group.position,
        {
          x: -6.5,
          y: 0,
        },
        0
      )
      .to(
        this.targets.group.scale,
        {
          x: 0.75,
          y: 0.75,
          z: 0.75,
        },
        0
      )
      .to(
        this.bgLines,
        {
          autoAlpha: 0.08,
        },
        0
      )
      .to(
        this.targets.particles.material,
        {
          opacity: 0,
        },
        0
      )
      .from(
        this.items,
        {
          autoAlpha: 0,
          y: 80,
          x: -80,
          stagger: 0.1,
        },
        0
      );
    return this.tl;
  };

  animateOverview = () => {
    this.tl = this.gsap.timeline({
      paused: true,
      ease: "power4.out",
      scrollTrigger: {
        trigger: "[data-chapter='uebersicht']",
        start: "top top+=80",
        end: "bottom top",
        // scrub: true,
        // markers: true,
      },
    });
    // this.tl.from(this.items, {
    //   autoAlpha: 0,
    //   y: 80,
    //   x: -80,
    //   stagger: 0.1,
    // });

    return this.tl;
  };

  onScroll = () => {
    this.chapters = this.gsap.utils.toArray(".chapter");
    // document.querySelectorAll(".chapter");
    const firstChapter = this.chapters.shift();
    let mm = this.gsap.matchMedia();
    mm.add("(min-width: 768px)", () => {
      this.gsap.to(firstChapter, {
        scrollTrigger: {
          trigger: firstChapter,
          endTrigger: this.chapters[0],
          start: "top top",
          end: "bottom center",
          scrub: 0.5,
          pin: `[data-title="${firstChapter.dataset.chapter}"]`,
          pinSpacing: false,
          anticipatePin: 1,
        },
      });
      this.chapters.forEach((chapter) => {
        this.gsap.to(chapter, {
          scrollTrigger: {
            trigger: chapter,
            start: "top top",
            end: "bottom center",
            scrub: 0.5,
            pin: `[data-title="${chapter.dataset.chapter}"]`,
            pinSpacing: false,
          },
        });
      });
    });
  };

  animateZielbilder = () => {
    this.tl = this.gsap.timeline({
      paused: true,
      ease: "power4.out",
      scrollTrigger: {
        trigger: "[data-chapter='zielbilder-one']",
        start: "top center",
        end: "top top",
        scrub: 0.5,

        onEnter: () => {
          this.targets.ball.visible = false;
          // this.targets.icosaheadron.visible = false;
          // this.targets.wireframe.visible = false;
        },
      },
    });
    this.tl
      .to(
        this.targets.hexagon.position,
        {
          x: 0,
        },
        0
      )
      .to(
        this.targets.wireframe.position,
        {
          x: 50,
        },
        0
      );

    return this.tl;
  };

  animateInitiative = () => {
    this.tl = this.gsap.timeline({
      paused: true,
      ease: "power4.out",
      duration: 1,
      scrollTrigger: {
        trigger: "[data-chapter='initiativen']",
        start: "top center",
        end: "top top",
        scrub: 0.5,

        onEnter: () => {
          this.targets.ball.visible = false;
        },
      },
    });
    this.tl
      .to(
        this.targets.hexagon.position,
        {
          x: 50,
        },
        0
      )
      .to(
        this.targets.icosaheadron.position,
        {
          x: 0,
        },
        0
      );
  };

  spinAnimation = (target, chapter, degrees, end) => {
    this.tl = this.gsap.timeline({
      paused: true,
      ease: "power4.out",
      scrollTrigger: {
        trigger: `[data-chapter='${chapter}']`,
        start: "top top",
        end: `bottom ${end}`,
        scrub: 0.5,
      },
    });
    this.tl.to(
      target.rotation,
      {
        y: MathUtils.degToRad(degrees),
      },
      0
    );

    return this.tl;
  };

  animateLeitlinien = () => {
    this.gsap.to;
  };

  leaveStrategy = () => {
    this.gsap.to(this.bgLines, {
      autoAlpha: 0,
    });
    this.gsap.to(this.targets.particles.material, {
      opacity: 0.5,
    });
    this.targets.ball.visible = true;
  };

  init() {
    this.enterStrategy();
    this.animateOverview();
    this.onScroll();
    this.animateZielbilder();
    this.animateInitiative();

    this.spinAnimation(this.targets.icosaheadron, "initiativen", 360, "bottom");

    this.gsap.to(this.targets.wireframe.rotation, {
      y: MathUtils.degToRad(180),
      scrollTrigger: {
        // trigger: "[data-chapter='overview']",
        trigger: "[data-chapter='digitalstrategie']",
        start: "top top",
        end: "bottom center",
        scrub: 0.5,
      },
    });

    this.targets.lineItem.forEach((child) => {
      this.gsap.to(child.scale, {
        x: 2,
        y: 2,
        z: 2,
        scrollTrigger: {
          trigger: "[data-chapter='leitlinien']",
          start: "top top",
          end: "bottom top",
          scrub: 0.5,
          onEnter: () => {},
          onLeave: () => {},
        },
      });
    });

    this.gsap.to(this.targets.icosaheadron.material, {
      opacity: 0,
      scrollTrigger: {
        trigger: "[data-chapter='leitlinien']",
        start: "top center",
        end: "+=100",
        scrub: 0.5,
      },
    });
  }

  destroy() {
    super.destroy();
    this.leaveStrategy();
  }
}
