import Experience from "../Experience";

export default class Homepage {
  constructor() {
    //initialize variables
    this.experience = new Experience();
    this.gsap = this.experience.gsap;

    this.scene = this.experience.scene;

    this.mouse = this.experience.mouseEvents;
    this.sizes = this.experience.sizes;

    this.group = this.experience.world.group;
    this.spheres = this.experience.world.spheres;

    //initiate functions
    this.parallax();
  }

  animateParallax = () => {
    this.gsap.to(this.group.rotation, {
      x: this.mouse.cursor.y * 0.1,
      y: this.mouse.cursor.x * -0.1,
      duration: 0.5,
    });
  };

  parallax() {
    this.mouse.getMouseCoordinates(this.animateParallax, this.spheres);
  }

  openVideo = () => {
    const videos = this.gsap.utils.toArray(".card");

    videos.forEach((video, index) => {
      video.addEventListener("click", (e) => {
        e.preventDefault();
        const player = document.querySelector(`[data-video="${index}"]`);
        const close = document.querySelector(
          `[data-video="${index}"] > .video__close`
        );
        player.classList.add("active");
        close.addEventListener("click", () => {
          player.classList.remove("active");
        });
      });
    });
  };

  resize() {}
}
