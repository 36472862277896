import Highway from "@dogstudio/highway";
import Experience from "../Experience/Experience";
import TranslationController from "../Translation/TranslationController";
import Navbar from "../Experience/Interactions/Navbar";

export default class BoardRenderer extends Highway.Renderer {
  constructor(properties) {
    super(properties);
    this.experience = new Experience();
    this.animations = this.experience.boardAnimations;
    this.navbar = new Navbar();
    this.translations = new TranslationController();
  }
  // Hooks/methods
  onEnter() {
    this.animations.init();

    this.translations.bindLocaleSwitcher(this.translations.currentLanguage);
    this.translations.translate();
  }
  onLeave() {
    this.animations.destroy();
  }
  onEnterCompleted() {
    this.animations.ballAnimation();
    this.navbar.setElements();
    this.navbar.animateMobileMenu();
  }
  onLeaveCompleted() {}
}
