import Experience from "../Experience.js";
export default class SetTargets {
  constructor() {
    this.experience = new Experience();

    this.world = this.experience.world;

    this.targets = {
      ball: this.world.ball,
      group: this.world.group,
      leitlinien: this.world.subgroup,
      wireframe: this.world.wireframe,
      particles: this.experience.particles.particles,
      icosaheadron: this.world.icosahedron,
      hexagon: this.world.hexagon,
      lineItem: this.world.leitlinien,
    };
  }
}
